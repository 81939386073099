<template>
  <!-- 新增访谈纪要 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    :showFooter="false"
    title="新增访谈信息"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          model: formData,
          labelWidth: '140px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="addFormData"
      >
      </base-form>
      <div class="head">
        <div class="text">受访信息</div>
        <div class="addbutton" @click="addData">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <BaseTable
        :columns="columns"
        :showPage="false"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :selfChangeHeight="180"
        ref="tableData"
        :webPage="true"
      >
        <template slot="code" slot-scope="scope">{{scope.$index + 1}}</template>
        <!-- 受访企业名称 -->
        <template slot="companyName" slot-scope="scope">
          <sz-input v-model="scope.row.companyName" placeholder="请输入"></sz-input>
        </template>
        <!-- 受访人员姓名 -->
        <template slot="personnelName" slot-scope="scope">
          <sz-input v-model="scope.row.personnelName" placeholder="请输入"></sz-input>
        </template>
        <!-- 受访人员职务 -->
        <template slot="personnelPost" slot-scope="scope">
          <sz-input v-model="scope.row.personnelPost" placeholder="请输入"></sz-input>
        </template>
        <template slot="action" slot-scope="scope">
          <IconButton
            @click.prevent="deleteData(scope.row, scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></IconButton>
        </template>
      </BaseTable>
    </div>
    <template slot="footer">
      <base-button label='保 存'></base-button>
      <base-button label='制作访谈纪要'></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { formConfig, dataTable } from '../utils/add-config.js'
import SzInput from '@/components/input/sz-input/sz-input.vue'

export default {
  name: 'previewDialog',
  props: {
    visible: Boolean
  },
  components: {
    BaseDialog,
    BaseButton,
    BaseForm,
    BaseTable,
    IconButton,
    SzInput
  },
  data () {
    return {
      formData: {},
      tableData: [{}]
    }
  },
  computed: {
    addForm () {
      return formConfig()
    },
    columns () {
      return dataTable()
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    addData () {
      this.tableData.push({})
    },
    // 删除
    deleteData (row, index) {
      this.tableData.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>

  .head {
    display: flex;
    margin: 10px 0 20px;
    .text {
      flex: 1;
    }
    .addbutton {
      margin-right: 10px;
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }

</style>
