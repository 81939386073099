// 表单数据
export const formConfig = (content) => {
  const span = 8
  return [
    {
      label: '访谈信息',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '访谈形式',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '访谈人员',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '访谈日期',
      prop: 'businessNo',
      attrs: {
        placeholder: '请选中',
        type: 'date'
      },
      tag: 'el-date-picker',
      span
    },
    {
      label: '起止时间',
      prop: 'businessNo',
      attrs: {
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始时间',
        'end-placeholder': '结束时间'
      },
      tag: 'el-date-picker',
      span
    },
    {
      label: '访谈地点',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '访谈目的',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 200,
        type: 'textarea'
      },
      span: 24
    }
  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '受访企业名称',
      prop: 'companyName'
    },
    {
      label: '受访人员姓名',
      prop: 'personnelName'
    },
    {
      label: '受访人员职务',
      prop: 'personnelPost'
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      width: '80px'
    }
  ]
}
