
export const fromConfig = (content) => {
  return [
    {
      label: '企业名称',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请输入'
      }
    },
    // {
    //   label: '访谈形式',
    //   prop: 'interview',
    //   attrs: {
    //     placeholder: '请输入'
    //   }
    // },
    {
      label: '最近一次访谈更新时间',
      prop: 'lastInterviewTime',
      attrs: {
        type: 'daterange',
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        'value-format': 'yyyy-MM-dd'
      },
      tag: 'el-date-picker'
    }
    // {
    //   label: '受访企业',
    //   prop: 'respondents',
    //   attrs: {
    //     placeholder: '请输入'
    //   }
    // }
  ]
}

export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'enterpriseName',
      minWidth: '150px'
    },
    {
      label: '成立日期',
      prop: 'foundTime',
      minWidth: '150px'
    },
    {
      label: '法人代表',
      prop: 'legalRepresentative',
      minWidth: '150px'
    },
    {
      label: '最近一次访谈更新时间',
      prop: 'lastInterviewTime',
      minWidth: '150px',
      formatter: row => {
        return row.lastInterviewTime === '1900-01-01 00:00:00' ? '' : row.lastInterviewTime
      }
    },
    {
      label: '最近一次访谈更新人员',
      prop: 'lastInterviewUserName',
      minWidth: '150px'
    },
    {
      label: '最近一次评论更新时间',
      prop: 'lastCommentsTime',
      minWidth: '150px',
      formatter: row => {
        return row.lastCommentsTime === '1900-01-01 00:00:00' ? '' : row.lastCommentsTime
      }
    },
    {
      label: '最近一次评论更新人员',
      prop: 'lastCommentsUserName',
      minWidth: '150px'
    },
    // {
    //   label: '纪要编号',
    //   prop: 'enterpriseName',
    //   minWidth: '150px'
    // },
    // {
    //   label: '访谈形式',
    //   prop: 'creditCode',
    //   minWidth: '250px'
    // },
    // {
    //   label: '访谈日期',
    //   prop: 'legalRepresentative',
    //   minWidth: '150px'
    // },
    // {
    //   label: '访谈目的',
    //   prop: 'legalRepresentative',
    //   minWidth: '150px',
    //   align: 'right'
    // },
    // {
    //   label: '受访企业',
    //   prop: 'legalRepresentative',
    //   minWidth: '150px',
    //   align: 'right'
    // },
    // {
    //   label: '访谈地点',
    //   prop: 'legalRepresentative',
    //   minWidth: '150px',
    //   align: 'right'
    // },
    // {
    //   label: '访谈人员',
    //   prop: 'legalRepresentative',
    //   minWidth: '150px',
    //   align: 'right'
    // },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
