<template>
  <div>
    <!--    表单-->
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      ref="fromdata"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    ></BaseForm>
    <!-- <div class="addbtn">
      <base-button
        label="新增"
        @click="addSupplier({url:'/addsupplier',type:'add'})"
        icon="el-icon-plus"
      ></base-button>
    </div> -->
    <!--    表格-->
    <Basetable
      :columns="columns"
      :api="api"
      :getApi="'getStatisticsPage'"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">

        <IconButton
          @click.prevent="updataInfo(scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        ></IconButton>
      </template>
    </Basetable>
    <!-- 新增访谈信息 -->
    <add-interview-info :visible.sync="visible"></add-interview-info>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
// import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import addInterviewInfo from './components/add-interview-info.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
export default {
  name: 'supplierManagement',
  components: {
    BaseForm,
    Basetable,
    IconButton,
    addInterviewInfo
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      visible: false
    }
  },
  mounted () {
    this.handleFilter()
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return supplierApi
    }
  },
  methods: {
    /**
     * 查询
     */
    handleFilter () {
      if (this.queryParas.lastInterviewTime && this.queryParas.lastInterviewTime.length > 0) {
        this.$set(this.queryParas, 'lastInterviewTimeStart', this.queryParas.lastInterviewTime[0])
        this.$set(this.queryParas, 'lastInterviewTimeEnd', this.queryParas.lastInterviewTime[1])
      } else {
        this.$set(this.queryParas, 'lastInterviewTimeStart', '')
        this.$set(this.queryParas, 'lastInterviewTimeEnd', '')
      }
      this.loadCount++
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    /**
     * 新增供应商
     * @param data
     */
    addSupplier (data) {
      // this.visible = true
      this.$router.push({
        path: '/CRM/interviewMinutesMake',
        query: {
          keyId: '',
          type: 'add'
        }
      })
    },
    /**
     * 编辑
     * @param keyId
     */
    updataInfo (row) {
      const obj = {
        gysId: row.enterpriseId
      }
      Storage.setLocal('admittanceInfo', obj)
      this.$router.push({
        path: '/CRM/interviewMinutesMake',
        query: {
          keyId: row.enterpriseId,
          type: 'edit'
        }
      })
    },
    /**
     * 查看详情
     * @param keyId
     */
    detailsInfo (keyId) {
      supplierApi.get(keyId).then((res) => {
        this.$router.push({
          path: '/addsupplier',
          query: {
            keyId: keyId,
            type: 'details'
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.addbtn {
  padding: 20px 0 10px 18px;
  margin-top: 10px;
  background: #fff;
  display: block;
}

.pd15 {
  padding: 15px;
}
</style>
